<template>
<div class="border border-gray-100 rounded-sm">
    <div class="bg-gray-50 px-3">
    <span class="font-sans text-gray-500 font-medium tracking-wider">SECCIÓN NO.{{ section }}</span>
</div>
<div class="flex flex-wrap px-2 py-2 gap-5">
        <span class="list-inside flex gap-1 items-center" v-for="(item, x) in data" v-bind:key="x" >
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-hash" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M5 9l14 0" />
                <path d="M5 15l14 0" />
                <path d="M11 4l-4 16" />
                <path d="M17 4l-4 16" />
                </svg>
        
        <span> {{item.condition ? item.condition : '' }} </span>
        </span>
        <div v-if="!data || data.length <= 0">
            <span class="text-gray-400 font-sans tracking-wide">SIN CONDICIONES DETECTADAS</span>
        </div>
    </div>
</div>
</template>

<script>


    export default {
        props: {
            section: {
                type: [Number, String],
                default: "N/A"
            },
            data: {
                type: Array,
                default: function() {return []}
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <v-card class="px-3 py-3">
        <div class="flex justify-end">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 hover:text-gray-400 cursor-pointer" @click="$emit('close')">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
        <v-card-text>
            <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                <div class="flex">
                    <img src="/svg/odontogram.svg" class="bg-gray-50 px-3 py-2 rounded-sm border border-gray-400" alt="" srcset="">
                </div>
                <div class="grid gap-0 grid-flow-row items-center justify-start">
                    <div class="grid grid-flow-row text-center ">
                        <span class="text-gray-500 font-sans font-semibold text-2xl">Detalle de Diagnostico</span>
                        <span class="text-gray-500 font-sans font-semibold text-xl">General - Secciones</span>
                    </div>
                    <span class="text-gray-400 font-sans text-center">Distribución de secciones</span>
                </div>

            </div>
            <div class="grid grid-cols-1 shadow-sm border border-gray-100 mt-3">
                <div class="bg-gray-50 px-3">
                    <span class="font-sans text-gray-500 font-medium tracking-wider">GENERAL</span>
                </div>
                <div class="flex flex-wrap px-2 py-2 gap-5">
                    <span class="list-inside flex gap-1 items-center" v-for="(item, x ) in sectiongeneral" v-bind:key="x" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-hash" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M5 9l14 0" />
                            <path d="M5 15l14 0" />
                            <path d="M11 4l-4 16" />
                            <path d="M17 4l-4 16" />
                            </svg>
                       
                      <span> {{item.condition ? item.condition : '' }}</span>
                    </span>
                    <div v-if="!sectiongeneral || sectiongeneral.length <= 0">
                        <span class="text-gray-400 font-sans tracking-wide">SIN CONDICIONES DETECTADAS</span>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5 mt-3">
                <da-tratament-card :section="1" :data="sectionone"></da-tratament-card>
                <da-tratament-card :section="2" :data="sectiontwo"></da-tratament-card>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5 mt-3">
                <da-tratament-card :section="3" :data="sectionthird"></da-tratament-card>
                <da-tratament-card :section="4" :data="sectionfour"></da-tratament-card>
            </div>
            <div class="grid grid-cols-1 gap-5 mt-3">
                <da-tratament-card :section="5" :data="sectionfive"></da-tratament-card>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DATratamentDetailCard from './DATratamentDetailCard.vue';
import fnTypes from '../../../store/modules/common/fn-types';
import moduleTypes from '../../../store/modules/common/module-types';

    export default {
        components: {
            'da-tratament-card': DATratamentDetailCard
        },
        mounted() {
            this.getConditions()
        },
        computed: {
            ...mapGetters( moduleTypes.CONDITION, {
                conditions:      fnTypes.conditions,
            }),
            ...mapGetters( moduleTypes.TREATMENT, {
                tratmentDetail:      fnTypes.getTratamentDetail,
            }),
            sectiongeneral: function() {
                let json = this.tratmentDetail.json && this.tratmentDetail.json.length > 0 ? this.tratmentDetail.json[0].general : null;
                let general = []
                if(json) {
                    general = json.conditions.length > 0 ? json.conditions.map((item) => {return { condition: this.conditions.find(x => x.id_cat_condition == item ) ? this.conditions.find(x => x.id_cat_condition == item ).label : '' }}): []
                }
                return general
            },
            sectionone: function() {
                let general = this.getSectionData(1)
                return general
            },
            sectiontwo: function() {
                let general = this.getSectionData(2)
                return general
            },
            sectionthird: function() {
                let general = this.getSectionData(3)
                return general
            },
            sectionfour: function() {
                let general = this.getSectionData(4)
                return general
            },
            sectionfive: function() {
                let general = this.getSectionData(5)
                return general
            }
        },
        methods: {
            ...mapActions(moduleTypes.CONDITION, [
                fnTypes.getConditions
            ]),
            getSectionData: function(sec) {
                let json = this.tratmentDetail.json && this.tratmentDetail.json.length > 0 ? this.tratmentDetail.json[0].sections : null;
                let general = []
                if(json) {
                    let section = json.find(x => x.part == sec)
                    general = section && section.conditions.length > 0 ? section.conditions.map((item) => {return { condition: this.conditions.find(x => x.id_cat_condition == item ) ? this.conditions.find(x => x.id_cat_condition == item ).label : '' }}): []
                }
                return general
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
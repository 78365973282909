<template>
  <div class="px-5 py-5">
    <da-loader v-bind:overlay="isLoading"></da-loader>
     <div class="sm:grid md:flex justify-between items-center font-sans text-gray-600 font-medium">
        <span>Yo, {{ px.person.fullname | uppercase }}</span>
        <span>EDAD: {{ px.person.age ? px.person.age : ''}} AÑOS</span>
        <span class="uppercase">FECHA: {{ new Date() | toDateMonthName }}</span>
     </div>
     <div class="border border-gray-500 px-2 py-2">
        <span class="font-sans text-gray-600"><span class="font-bold">DECLARO: </span> {{configuration.state}}</span>
     </div>
     <div class="sm:px-0 md:px-5 py-5">
      <da-datatable :headers="headers" :values="tratament" :elevation="0" :hidepagination="false">
        <template #sec="{data}">
          <span class="cursor-pointer hover:text-primary-1000" @click="onTratamentDetail(data)">{{ data.sec }}</span>
        </template>
        <template #tratment="{data}">
          <span class="cursor-pointer hover:text-primary-1000"  @click="onTratamentDetail(data)">{{ data.tratment ? data.tratment : '' }}</span>
        </template>
        <template #treatment="{data}">
          <span class="cursor-pointer hover:text-primary-1000" @click="onTratamentEdit(data)" v-if="data.treatment">{{ data.treatment ? data.treatment : '' }}</span>
          <span class="cursor-pointer hover:text-primary-1000" @click="onTratamentEdit(data)" v-else>{{ 'NO APLICA' }}</span>
        </template>
        <template #amount="{data, header}">
                <da-scoped-column>
                    <template #header>{{header ? header : '' }}</template>
                    <template #value  >
                      <span class="cursor-pointer hover:text-primary-1000" @click="onTratamentEdit(data)" > {{ data.amount | currency }}</span>
                    </template>
                </da-scoped-column>
        </template>
      </da-datatable>

      <div class="py-2 mb-1">
         <span class="font-sans font-medium">OTROS</span>

        <v-tooltip bottom >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="openAddOtherTreatment()" class="float-right">
              <v-icon  color="#E7AC18">mdi-file-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar Registro</span>
        </v-tooltip>

      </div>
      <div>
        <da-datatable :headers="extraheaders" :values="getOTreatments" :elevation="0" :hidepagination="false">
          <template #amount="{data, header}">
                  <da-scoped-column>
                    <template #header>{{header ? header : '' }}</template>
                    <template #value> {{ data.amount | currency }} </template>
                </da-scoped-column>
          </template>
          <template #action="{ data }">
                <div class="flex justify-center">
                  <template> 
                      <da-button-primary @click.native="showEditOtherTreat(data)" :label="'Detalle'" r2xl class="text-xs w-full px-1  tracking-wider rounded-r-none"></da-button-primary>
                      <da-button-900 @click.native="showDelete(data.id_treatment)" :label="'Eliminar'" r2xl class="text-xs w-full px-1  tracking-wider rounded-l-none"></da-button-900>
                  </template>

                </div>
          </template>
        </da-datatable>
      </div>
      <!-- <div class="py-2">
         <span class="font-sans font-medium">DIAGRAMA DENTAL</span>
         <div>
           <da-odontogram :id="px.person.id_person" ></da-odontogram>
         </div>
      </div> -->

      <div class="flex justify-end items-center py-2">
          <div class="grid gap-y-2">
            <span  class="font-sans font-medium">Plan:<span class="text-gray-500 font-normal"> {{ subtotalplan | currency}}  </span> </span>
            <span  class="font-sans font-medium mr-5">Extras: <span class="text-gray-500 font-normal">{{ subtotalextraplan | currency}} </span> </span>
            <hr>
            <span  class="font-sans font-medium mr-5">Subtotal: <span class="text-gray-500 font-normal"> {{ subtotal | currency}} </span> </span>
          </div>
      </div>

      <div>

      </div>

     </div>
     <div class="border border-gray-500 px-2 py-2 rounded-md">
        <span class="font-sans text-gray-600"><span class="font-bold">CONSIENTO: </span> {{configuration.consent}}</span>
     </div>



     <div class="sm:grid md:grid-cols-2 justify-between mt-20 gap-x-10 gap-y-20">
      
      <div class="w-auto justify-center text-center mb-5">
        <img   :src="this.px.person.id_signature" alt="Firma" srcset="" style="min-height: 12rem;">
        <div class="border-t-2 border-gray-500">
          <span class="font-sans font-semibold tracking-wider text-gray-500 text-center">{{ px.person.fullname | uppercase }}</span>
        </div>  
      </div>


      <div class="w-auto justify-center text-center mb-5">
        <img   :src="this.configuration.url_signature" alt="Firma2" srcset="" style="min-height: 12rem;">
        <div class="border-t-2 border-gray-500">
          <span class="font-sans font-semibold tracking-wider text-gray-500 text-center">{{ 'C.D. Gpe. De los Ángeles  Orrico Velázquez' | uppercase}}</span>
        </div>  
      </div>
      
     </div>

    <v-dialog v-model="showAddOtherT" persistent max-width="800" >
      <v-card>
        <div class="flex mb-5">
          <span class="text-xl ml-5 mt-4">Agregar Tratamiento</span> 
            <div class="flex justify-end items-center mr-4 mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" @click="showAddOtherT = false" class="icon icon-tabler icon-tabler-square-x text-gray-400 cursor-pointer" width="35" height="35" viewBox="0 0 24 24" stroke-width="1" stroke="#BDBDBD" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="4" y="4" width="16" height="16" rx="2" />
                <path d="M10 10l4 4m0 -4l-4 4" />
                </svg>
            </div>
        </div>
        <v-card-text>

          <da-text-field-primary 
              :label="'Tratamiento:'"
              :value="OtherTreatment.description"
              v-model="OtherTreatment.description"
              text
          />

          <da-text-field-primary 
            :label ="'Costo:'"
            :value ="OtherTreatment.amount"
            v-model ="OtherTreatment.amount"
            number
          />

      <div class="flex justify-end">
            <v-btn class="mt-5" color="#F8C73D" :rounded="true" size="x-large"   @click="onSaveOtherTreatment()">
              <v-icon class="mr-1" color="white">mdi mdi-content-save-outline</v-icon>
              <p class="text-white mt-4">Guardar</p>
            </v-btn>
            
            <v-btn class="mt-5 ml-2" color="#A6001E" :rounded="true" size="x-large"   @click="showAddOtherT = false">
              <v-icon class="mr-1" color="white">mdi-delete-circle</v-icon>
              <p class="text-white mt-4">Cancelar</p>
            </v-btn>
      </div>
        </v-card-text>
      </v-card>
    </v-dialog>

        <v-dialog v-model="showEditOtherT" persistent max-width="800" >
      <v-card>
        <div class="flex mb-5">
          <span class="text-xl ml-5 mt-4">Agregar Tratamiento</span> 
            <div class="flex justify-end items-center mr-4 mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" @click="showEditOtherT = false" class="icon icon-tabler icon-tabler-square-x text-gray-400 cursor-pointer" width="35" height="35" viewBox="0 0 24 24" stroke-width="1" stroke="#BDBDBD" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="4" y="4" width="16" height="16" rx="2" />
                <path d="M10 10l4 4m0 -4l-4 4" />
                </svg>
            </div>
        </div>
        <v-card-text>

          <da-text-field-primary 
              :label="'Tratamiento:'"
              :value="OtherTreatmentE.description"
              v-model="OtherTreatmentE.description"
              text
          />

          <da-text-field-primary 
            :label ="'Costo:'"
            :value ="OtherTreatmentE.amount"
            v-model ="OtherTreatmentE.amount"
            number
          />

      <div class="flex justify-end">
            <v-btn class="mt-5" color="#F8C73D" :rounded="true" size="x-large"   @click="onEditOtherTreatment()">
              <v-icon class="mr-1" color="white">mdi mdi-content-save-outline</v-icon>
              <p class="text-white mt-4">Guardar</p>
            </v-btn>
            
            <v-btn class="mt-5 ml-2" color="#A6001E" :rounded="true" size="x-large"   @click="showEditOtherT = false">
              <v-icon class="mr-1" color="white">mdi-delete-circle</v-icon>
              <p class="text-white mt-4">Cancelar</p>
            </v-btn>
      </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <da-dialog v-model="isDelete" :persist="true" :maxWidth="'500px'">
        <template #body>
            <da-sweet-confirm 
            :title="'Eliminar Registro'" 
            :message="'¿Esta seguro de eliminar el registro de seguimiento seleccionado?'"
            :lottiepath="DELETE"
            :iconwidth="150"
            :iconheight="150"
            v-on:confirm="OnDeleteOtherTreatment()" 
            v-on:close="isDelete = false">
        </da-sweet-confirm>
        </template>
    </da-dialog>

    <da-dialog v-model="isDetail" :persist="true">
        <template #body>
            <da-tratament-detail
            v-on:close="onCloseDetail"
            v-if="isDetail"
            >
            </da-tratament-detail>
        </template>
    </da-dialog>
    <da-dialog v-model="isDetailEdit" :persist="true">
        <template #body>
            <da-tratament-detail-edit
            v-on:close="onCloseEdit"
            v-if="isDetailEdit"
            >
            </da-tratament-detail-edit>
        </template>
    </da-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VuexMixin from '../../../mixins/VuexMixin';
import moduleTypes from '../../../store/modules/common/module-types';
import mutationTypes from '../../../store/modules/common/mutation-types';
import fnTypes from '../../../store/modules/common/fn-types';
import DASweetConfirm from '../../../core/components/form/dialog/components/DASweetConfirm.vue';
import DATratamentDetail from '../components/DATratamentDetail.vue';
import DATratamentEdit from '../components/DATratamentEdit.vue';
import { DELETE } from '../../../utils/lottieicon';

export default {
  mixins: [VuexMixin],
  props: {
    px: {
      type: Object,
      default: function(){return {}}
    }
  },
  components: {
    'da-sweet-confirm': DASweetConfirm,
    'da-tratament-detail': DATratamentDetail,
    'da-tratament-detail-edit': DATratamentEdit,
  },
  data: function() {
    return {
      OtherTreatment: {
        description: '',
        amount: 0
      },
      OtherTreatmentE: {
        description: '',
        amount: 0,
        id:''
      },
      showAddOtherT: false,
      showEditOtherT: false,
      isLoading: false,
      isDelete: false,
      DELETE,
      ida:'',
      headers: [
          { text: 'Región Permanente', value: 'sec' },
          { text: 'Diagnóstico o tratamiento', value: 'tratment' },
          { text: 'Tratamientos', value: 'treatment' },
          { text: 'Costo', value: 'amount' }
      ],
      extraheaders: [
          { text: 'Tratamientos', value: 'description' },
          { text: 'Costo', value: 'amount' },
          { text: 'Acciones', value: 'action' }
      ],

      // configuration:{
      //   id_signature_consent:'',
      //   consent: '',
      //   key_signature: '',
      //   name: '',
      //   state: '',
      //   url_signature: ''
      // },
      isDetail: false,
      isDetailEdit: false,
      tratament: [],
      tratamentSelected: null
    }
  },
  created() {
      this.isLoading = true;
      this.init()
      // this.onGetOtherTreatment()
      this.onGetConfiguration()
      this.getTratament(this.px.person.id_person)
      this.isLoading = false
  },
  computed: {

    subtotalplan: function() {
        return this.tratament.length > 0  ? this.tratament.map(item => parseFloat(item.amount) ).reduce((prev, next) => prev + next, 0) : 0
    },
    subtotalextraplan: function() {
        return  this.tratament.length > 0 ? this.getOTreatments.map(item => parseFloat(item.amount) ).reduce((prev, next) =>  prev + next, 0) : 0
    },
    subtotal: function() {
        return this.subtotalplan + this.subtotalextraplan;
    },

    ...mapGetters(moduleTypes.TREATMENT, {
      saveOTreatments:    fnTypes.saveOTreatment,
      editOTreatments:    fnTypes.editOTreatment,
      getOTreatments:     fnTypes.getOTreatment,
      deleteOTreatments:  fnTypes.deleteOTreatment,
      tratamentData:       fnTypes.getTratament

    }),

    ...mapGetters( moduleTypes.CONFIGURATION, {
      configuration:      fnTypes.getConfiguration,
    }),

    ...mapGetters( moduleTypes.CONDITION, {
      conditions:      fnTypes.conditions,
    }),
  },

  methods:{
    ...mapActions(moduleTypes.TREATMENT,  [
        fnTypes.saveOTreatment,
        fnTypes.editOTreatment,
        fnTypes.getOTreatment,
        fnTypes.deleteOTreatment,
        fnTypes.getTratament
      ]),
    ...mapActions(moduleTypes.CONFIGURATION, [
        fnTypes.getConfiguration
      ]),

    ...mapMutations(moduleTypes.TREATMENT, [
        mutationTypes.SET_TRATAMENT_EDIT,
        mutationTypes.SET_TRATAMENT_DETAIL
    ]),

    ...mapActions(moduleTypes.CONDITION, [
        fnTypes.getConditions
    ]),

    init: async function() {
      await this.ActionWrapp(moduleTypes.CONDITION, fnTypes.getConditions)
      await this.ActionWrapp(moduleTypes.TREATMENT, fnTypes.getOTreatment, this.px.person.id_person)
    },

    openAddOtherTreatment: function(){
      this.showAddOtherT = true
      this.OtherTreatment.description = ''
      this.OtherTreatment.amount = 0
    },

    onSaveOtherTreatment: async function(){
        
        let data  = {
          "treatment":    [
          {
            "description":  this.OtherTreatment.description,
            "amount":       this.OtherTreatment.amount
          }
        ],
          "id_person":  this.px.person.id_person
        }

        if (this.OtherTreatment.description == '') {
          return 
        } else {
          this.isLoading = true
          await this.saveOTreatment(data)
        }
        
        if(this.saveOTreatments){
          this.showAddOtherT = false
          this.onGetOtherTreatment()
        }
        this.isLoading = false
    },

    onEditOtherTreatment: async function(){
        
        let data  = {
          "treatment":    [
          {
            "id_treatment":  this.OtherTreatmentE.id,
            "description":  this.OtherTreatmentE.description,
            "amount":       this.OtherTreatmentE.amount
          }
        ],
          "id_person":  this.px.person.id_person
        }

        if (this.OtherTreatmentE.description == '') {
          return 
        } else {
          this.isLoading = true
          await this.editOTreatment(data)
        }
        
        if(this.editOTreatments){
          this.showEditOtherT = false
          this.onGetOtherTreatment()
        }
        this.isLoading = false
    },

    onGetOtherTreatment: async function(){
      
        this.isLoading = true
        await this.getOTreatment(this.px.person.id_person)
        this.isLoading = false
    },

    OnDeleteOtherTreatment: async function(){
      console.log(this.ida)
        this.isLoading = true
        let data = {
          "id_treatment": this.ida,
          "id_person": this.px.person.id_person
        }
        await this.deleteOTreatment(data)
        this.onGetOtherTreatment()
        this.isDelete = false
        this.isLoading = false
    },

    showDelete: function(id){
        this.ida = id
        this.isDelete = true
    },

    showEditOtherTreat: function(obj){
        this.OtherTreatmentE.description = obj.description
        this.OtherTreatmentE.amount = obj.amount
        this.OtherTreatmentE.id = obj.id_treatment
        this.showEditOtherT = true
    },

    onGetConfiguration: async function(){
        await this.getConfiguration()
    },
    onTratamentDetail: function(data) {
      this.SET_TRATAMENT_DETAIL(data)
      this.isDetail = true;
    },
    onCloseDetail: function() {
      this.SET_TRATAMENT_DETAIL(null)
      this.isDetail = false;
    },
    onTratamentEdit: function(data) {
      this.SET_TRATAMENT_EDIT(data)
      this.isDetailEdit = true;
    },
    onCloseEdit: function() {
      this.SET_TRATAMENT_EDIT(null)
      this.isDetailEdit = false
    }
  },

  watch: {
    tratamentData: function() {
      if(this.tratamentData && this.tratamentData.length > 0) {
        this.tratament = this.tratamentData.map((item) => {return {...item, tratment: this.conditions.find(x => x.identity == item.symbol ) ? this.conditions.find(x => x.identity == item.symbol ).label : ''  }})
      }
    }
  }

}
</script>

<style scoped>

.v-text-field__slot input {

      color: rgba(192, 0, 0, 0.33);
    
  }

</style>
<template>
    <v-card  class="px-3 py-3">
        <div class="flex justify-end">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 hover:text-gray-400 cursor-pointer" @click="$emit('close')">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
        <v-card-text class="py-3" v-if="treatmentData">
            <div class="bg-gray-50 px-2">
                <small class="text-gray-400 font-sans tracking-wider">Actualiza el tratamiento y los costos</small>
            </div>
            <div class="grid grid-cols-1 px-1 py-1 gap-5 mt-3">
                <da-text-field-primary 
                    :label="'Tratamiento:'"
                    :value="$v.treatmentData.treatment"
                    :error="$v.treatmentData.treatment.$invalid && $v.treatmentData.treatment.$anyDirty" 
                    v-model="$v.treatmentData.treatment.$model"
                    text
                />
            </div>
            <div class="grid grid-cols-1 px-1 py-1 gap-5 mt-3">
                <da-text-field-primary 
                    :label="'Costo:'"
                    :value="$v.treatmentData.amount"
                    :error="$v.treatmentData.amount.$invalid && $v.treatmentData.amount.$anyDirty" 
                    v-model="$v.treatmentData.amount.$model"
                    number
                />
            </div>
        </v-card-text>
        <div class="flex justify-end">
              <div class="flex gap-2 justify-end align-middle">
                  <da-button-primary @click.native="onSave"  :label="'GUARDAR'" r2xl swpr class="py-1 px-5 font-light"></da-button-primary>
                  <da-button-secondary @click.native="$emit('close')" :label="'CANCELAR'" rxl swsec class="py-1 px-5 font-light"></da-button-secondary>
              </div>
          </div>
    </v-card>
</template>

<script>
import {  mapGetters, mapActions } from 'vuex';
import fnTypes from '../../../store/modules/common/fn-types';
import moduleTypes from '../../../store/modules/common/module-types';
import DATreatmentModel from '../../../models/treatment/DATreatment.model';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';


    export default {
        mixins: [validationMixin],
        data: function() {
            return {
                treatmentData: new DATreatmentModel()
            }
        },
        validations: {
            treatmentData: {
                treatment: {
                    required
                },
                amount: {
                    required
                }
        }
    },
    computed: {
        ...mapGetters(moduleTypes.TREATMENT, {
             tratmentEdit:    fnTypes.getTratamentEdit,
             hasError:    fnTypes.hasErrorTreatment,
        }),
    },
    mounted() {
        this.getTratmentData()
    },
    methods: {
        ...mapActions(moduleTypes.TREATMENT, [
            fnTypes.saveTratament,
        ]),
        getTratmentData: function() {
            if(this.tratmentEdit) {
                let {amount, id_diagnosis, id_odontogram, id_person, treatment} = this.tratmentEdit;
                this.treatmentData.amount = amount
                this.treatmentData.id_diagnosis = id_diagnosis
                this.treatmentData.id_odontogram = id_odontogram
                this.treatmentData.id_person = id_person
                this.treatmentData.treatment = treatment
            }
        },
        onSave: async function() {
            this.$v.$touch();
              if(!this.$v.$anyError) {
                await this.saveTratament(this.treatmentData)
                if(!this.hasError.error) {
                    this.$emit('close')
                }
              }
        }
    },
    watch: {

      
    }
}
</script>

<style lang="scss" scoped>

</style>